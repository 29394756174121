@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Rungli';
  src: url('/src/assets/fonts/Rungli\ Typeface/web/Rungli-Regular.woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rungli';
  src: url('/src/assets/fonts/Rungli\ Typeface/web/Rungli-Italic.woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/src/assets/fonts/helvetica-neue-5/HelveticaNeueRoman.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/src/assets/fonts/helvetica-neue-5/HelveticaNeueBold.otf');
  font-weight: 700;
  font-style: bold;
}